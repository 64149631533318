html,
body {
  background: #191f2c !important;
  padding: 0;
  margin: 0;
  height: 100%;
  color: white;
  font-family: 'Roboto', sans-serif;
}

#__next {
  height: 100%;
}

::placeholder {
  color: #a0afc0;
}

@import './fixes.css';
@import './nprogress.css';
