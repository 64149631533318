.MuiDataGrid-cell,
.MuiDataGrid-colCell {
  outline: none !important;
  cursor: pointer;
}

.MuiDataGrid-cell:hover .copy-button {
  display: flex;
}

.unclickable .MuiDataGrid-cell,
.unclickable .MuiDataGrid-colCell {
  cursor: auto !important;
}

.MuiDataGrid-columnHeaderTitle {
  /* text-transform: uppercase; */
  font-weight: 700;
  font-size: 14px;
}

.MuiDataGrid-root .MuiDataGrid-row:hover {
  background-color: rgba(81, 192, 172, 0.08) !important;
}

.MuiDataGrid-root .MuiIconButton-root {
  color: white !important;
}

.MuiDataGrid-root .MuiIconButton-root.Mui-disabled {
  color: rgba(255, 255, 255, 0.26) !important;
}

.MuiDataGrid-footer {
  border-top: 1px solid;
}

.MuiDataGrid-overlay {
  height: 100% !important;
}

.MuiChip-root {
  border-color: white !important;
  color: white !important;
  margin-bottom: 6px !important;
  margin-top: 6px !important;
}

.MuiAutocomplete-inputRoot {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.MuiFormHelperText-root {
  opacity: 0.5;
}

.MuiInputBase-root.Mui-disabled,
.MuiInput-input.Mui-disabled,
.MuiFormLabel-root.Mui-disabled {
  -webkit-text-fill-color: rgba(255, 255, 255, 0.2) !important;
  color: rgba(255, 255, 255, 0.2) !important;
}

.MuiInputLabel-root {
  transform: translate(0, -5px) scale(0.75);
}

.MuiSvgIcon-root.MuiSelect-icon.Mui-disabled,
.MuiAutocomplete-popupIndicator.Mui-disabled {
  color: rgba(255, 255, 255, 0.3) !important;
}

.MuiInput-underline:before {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
}

.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
  background-color: rgba(255, 255, 255, 0.04) !important;
}

.MuiButton-textPrimary.Mui-disabled {
  color: #51c0ac !important;
  opacity: 0.4;
}

.MuiButton-contained.Mui-disabled {
  color: rgba(255, 255, 255, 0.3) !important;
}

.MuiTab-textColorPrimary.Mui-disabled {
  color: rgba(255, 255, 255, 0.2) !important;
}

.file-field .MuiButton-label {
  text-transform: capitalize !important;
}

.MuiListItem-button:hover,
.MuiAutocomplete-option:hover,
.MuiAutocomplete-option[aria-selected='true'],
.MuiAutocomplete-option[data-focus='true'] {
  background: rgba(255, 255, 255, 0.07) !important;
}

.metadata-table div[role='cell'] {
  padding: 0 8px !important;
}

.metadata-table div[role='columnheader'] {
  padding: 0 8px !important;
  font-size: 13px !important;
}

/* .MuiDataGridColumnsPanel-column {
  text-transform: uppercase;
} */

.MuiDataGrid-columnSeparator {
  display: none;
}

.MuiListItemIcon-root {
  min-width: 40px !important;
}

.MuiAutocomplete-loading.mapbox-search {
  display: none;
}

.MuiAutocomplete-noOptions.mapbox-search {
  display: none;
}
